import {AbstractControl, ValidationErrors} from '@angular/forms';
import {isDateFormatValid, isEmpty} from '@matchsource/utils';

export const DATE_FORMAT_VALIDATOR_KEY = 'valid-date-format';

export const dateFormatValidator =
  (format: string) =>
  (control: AbstractControl): ValidationErrors | null => {
    const isValid = isEmpty(control.value) || isDateFormatValid(control.value, format);

    return !isValid ? {[DATE_FORMAT_VALIDATOR_KEY]: {value: control.value}} : null;
  };
